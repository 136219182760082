<template>
    <div id="terms-and-condition">
        <b-container fluid>
            <b-row>
                <b-col sm="12">
                    <h1 class="text-center m-auto">Terms & Conditions</h1>
                </b-col>
                <b-col sm="12">
                    <div class="text-right" id="date-right">
                        <p>Updated on: 20th July 2021</p>
                        <p>Effective Date: 20th July 2021</p>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <p class="paragraph">THIS IS AN AGREEMENT BETWEEN YOU OR THE ENTITY THAT YOU REPRESENT
                        (hereinafter
                        “You” or
                        “Your”) AND HouseOfBanquet BUSINESS SOLUTIONS PRIVATE LIMITED (hereinafter “HouseOfBanquet”)
                        GOVERNING
                        YOUR
                        USE OF
                        HTTPS://HOUSEOFBANQUETS.COM AND ITS MOBILE APPLICATION HOB AND HOB - GUEST ON ANDROID
                        PLAY
                        STORE.</p>
                </b-col>
                <b-col sm="12">
                    <h2 class="sub-heading">Parts of this Agreement</h2>
                    <p class="paragraph">This Agreement consists of the following terms and conditions
                        (hereinafter
                        the
                        “General
                        Terms”) and terms and conditions, if any, specific to use of individual Services
                        (hereinafter
                        the
                        “Service Specific Terms”). The General Terms and Service Specific Terms are collectively
                        referred to
                        as
                        the “Terms”. In the event of a conflict between the General Terms and Service Specific
                        Terms,
                        the
                        Service Specific Terms shall prevail.</p>
                </b-col>
                <b-col sm="12">
                    <h2 class="sub-heading">Acceptance of the Terms</h2>
                    <p class="paragraph">You must be of legal age to enter into a binding agreement in order to
                        accept
                        the
                        Terms.
                        If you do not agree to the General Terms, do not use any of our Services. If you agree
                        to
                        the
                        General
                        Terms and do not agree to any Service Specific Terms, do not use the corresponding
                        Service.
                        You
                        can
                        accept the Terms by checking a checkbox or clicking on a button indicating your
                        acceptance
                        of
                        the
                        terms
                        or by actually using the Services.</p>
                </b-col>
                <b-col sm="12">
                    <h2 class="sub-heading">Description of Service</h2>
                    <p class="paragraph">
                        We provide an array of services for online collaboration and management including
                        email client, lead management, pipeline management, organizer, customer relationship
                        management application and project management application ("Service" or "Services").
                        You
                        may use the Services
                        for your personal and business use or for internal business purpose in the
                        organization
                        that you
                        represent.
                        You
                        may
                        connect to the Services using any Internet browser supported by the Services. You
                        are
                        responsible
                        for
                        obtaining access to the Internet and the equipment necessary to use the Services.
                        You
                        can
                        create
                        and
                        edit content with your user account and if you choose to do so, you can publish and
                        share
                        such
                        content.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Subscription to Beta Service</p>
                    <p class="paragraph">We may offer certain Services as closed or open beta services
                        ("Beta
                        Service"
                        or
                        “Beta
                        Services”) for the purpose of testing and evaluation. You agree that we have the
                        sole
                        authority
                        and
                        discretion to determine the period of time for testing and evaluation of Beta
                        Services.
                        We
                        will
                        be
                        the
                        sole judge of the success of such testing and the decision, if any, to offer the
                        Beta
                        Services
                        as
                        commercial services. You will be under no obligation to acquire a subscription
                        to use
                        any
                        paid
                        Service
                        as a result of your subscription to any Beta Service. We reserve the right to
                        fully or
                        partially
                        discontinue, at any time and from time to time, temporarily or permanently, any
                        of the
                        Beta
                        Services
                        with or without notice to you. You agree that HouseOfBanquet will not be liable to
                        you or to
                        any
                        third
                        party
                        for any harm related to, arising out of, or caused by the modification,
                        suspension or
                        discontinuance
                        of
                        any of the Beta Services for any reason.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Modifcation of Terms of Service</p>
                    <p class="paragraph">We may modify the Terms upon notice to you at any time through
                        a
                        service
                        announcement
                        or
                        by sending email to your primary email address. If we make significant changes
                        to the
                        Terms
                        that
                        affect
                        your rights, you will be provided with at least 30 days advance notice of the
                        changes by
                        email
                        to
                        your
                        primary email address. You may terminate your use of the Services by providing
                        HouseOfBanquet
                        notice
                        by
                        email
                        within 30 days of being notified of the availability of the modified Terms if
                        the Terms
                        are
                        modified
                        in
                        a manner that substantially affects your rights in connection with use of the
                        Services.
                        In
                        the
                        event
                        of
                        such termination, you will be entitled to prorated refund of the unused portion
                        of any
                        prepaid
                        fees.
                        Your continued use of the Service after the effective date of any change to the
                        Terms
                        will
                        be
                        deemed
                        to
                        be your agreement to the modified Terms.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">User Sign up Obligations</p>
                    <p class="paragraph">You need to sign up for a user account by providing all
                        required
                        information in
                        order
                        to
                        access or use the Services. If you represent an organization and wish to use the
                        Services
                        for
                        corporate
                        internal use, we recommend that you, and all other users from your organization,
                        sign up
                        for
                        user
                        accounts by providing your corporate contact information. In particular, we
                        recommend
                        that
                        you
                        use
                        your
                        corporate email address. You agree to: a) provide true, accurate, current and
                        complete
                        information
                        about
                        yourself as prompted by the sign up process; and b) maintain and promptly update
                        the
                        information
                        provided during sign up to keep it true, accurate, current, and complete. If you
                        provide
                        any
                        information
                        that is untrue, inaccurate, outdated, or incomplete, or if</p>


                    <p class="paragraph">HouseOfBanquet has reasonable grounds to suspect that such information
                        is
                        untrue,
                        inaccurate,
                        outdated, or incomplete, HouseOfBanquet may terminate your user account and refuse
                        current or
                        future
                        use
                        of
                        any or all of the Services.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Organization Accounts and Administrators</p>
                    <p class="paragraph">When you sign up for an account for your organization you may
                        specify
                        one
                        or
                        more
                        administrators. The administrators will have the right to configure the Services
                        based
                        on
                        your
                        requirements and manage end users in your organization account. If your
                        organization
                        account
                        is
                        created
                        and configured on your behalf by a third party, it is likely that such third
                        party has
                        assumed
                        administrator role for your organization. Make sure that you enter into a
                        suitable
                        agreement
                        with
                        such
                        third party specifying such party’s roles and restrictions as an administrator
                        of your
                        organization
                        account.</p>
                    <p class="paragraph">You are responsible for i) ensuring confidentiality of your
                        organization
                        account
                        password, ii) appointing competent individuals as administrators for managing
                        your
                        organization
                        account,
                        and iii) ensuring that all activities that occur in connection with your
                        organization
                        account
                        comply
                        with this Agreement. You understand that HouseOfBanquet is not responsible for
                        account
                        administration
                        and
                        internal management of the Services for you.</p>
                    <span class="paragraph">You are responsible for taking necessary steps for ensuring
                                that your
                                organization
                                does
                                not lose control of the administrator accounts. You may specify a process to be
                                followed
                                for
                                recovering
                                control in the event of such loss of control of the administrator accounts by
                                sending an
                                email
                                to <a
                            href="mailto:info@houseofbanquets.com"><span
                            class="ft5">info@houseofbanquets.com</span></a>,
                                provided
                                that
                                the process is acceptable to HouseOfBanquet. In the absence of any specified
                                administrator
                                account
                                recovery
                                process, HouseOfBanquet may provide control of an administrator account to an
                                individual
                                providing
                                proof
                                satisfactory to HouseOfBanquet demonstrating authorization to act on behalf of the
                                organization.
                                You
                                agree
                                not to hold HouseOfBanquet liable for the consequences of any action taken by
                                HouseOfBanquet in
                                good
                                faith
                                in
                                this
                                regard.
                            </span>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Personal Information and Privacy</p>
                    <span class="paragraph">Personal information you provide to HouseOfBanquet through the
                                Service is
                                governed
                                by
                                <span
                                    class="ft6">HOB - Privacy Policy</span>. Your election to use the Service
                                indicates
                                your
                                acceptance of
                                the terms of the HOB- <a href="http://www.ritsworld.com/privacy-policy"><span
                            class="ft5">Privacy Policy</span></a>. You are responsible for maintaining
                                confidentiality
                                of
                                your
                                username, password and other sensitive information. You are responsible for all
                                activities
                                that
                                occur in
                                your user account and you agree to inform us immediately of any unauthorized use
                                of your
                                user
                                account by
                                email to <a href="mailto:info@houseofbanquets.com"><span
                            class="ft5">info@houseofbanquets.com</span></a>.
                                We
                                are
                                not responsible for any loss or damage to you or to any third party incurred as
                                a result
                                of
                                any
                                unauthorized access and/or use of your user account, or otherwise.
                            </span>
                    ,
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Communications from HouseOfBanquet</p>
                    <p class="paragraph">The Service may include certain communications from HouseOfBanquet, such as
                        service
                        announcements, administrative messages and newsletters. You understand that these
                        communications
                        shall be considered part of using the Services. As part of our policy to provide you
                        total
                        privacy,
                        we also provide you the option of opting out from receiving newsletters from us.
                        However,
                        you
                        will
                        not be able to
                        <NOBR>opt-out</NOBR>
                        from receiving service announcements and administrative messages.
                    </p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Complaints</p>
                    <p class="paragraph">If we receive a complaint from any person against you with respect to
                        your
                        activities
                        as part of use of the Services, we will forward the complaint to the primary email
                        address
                        of
                        your
                        user account. You must respond to the complainant directly within 10 days of receiving
                        the
                        complaint
                        forwarded by us and copy HouseOfBanquet in the communication. If you do not respond to the
                        complainant
                        within 10 days from the date of our email to you, we may disclose your name and contact
                        information
                        to the complainant for enabling the complainant to take legal action against you. You
                        understand
                        that your failure to respond to the forwarded complaint within the 10 days’ time limit
                        will
                        be
                        construed as your consent to disclosure of your name and contact information by
                        HouseOfBanquet to
                        the
                        complainant.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Restrictions on Use</p>
                    <p class="paragraph">In addition to all other terms and conditions of this Agreement, you
                        shall
                        not:
                        (i)
                        transfer the Services or otherwise make it available to any third party; (ii) provide
                        any
                        service
                        based on the Services without prior written permission; (iii) use the third party links
                        to
                        sites
                        without agreeing to their website terms & conditions; (iv) post links to third party
                        sites
                        or
                        use
                        their logo, company name, etc. without their prior written permission; (v) publish any
                        personal
                        or
                        confidential information belonging to any person or entity without obtaining consent
                        from
                        such
                        person or entity; (vi) use the Services in any manner that could damage, disable,
                        overburden,
                        impair
                        or harm any server, network, computer system, resource of HouseOfBanquet; (vii) violate any
                        applicable
                        local, state, national or international law; and (viii) create a false identity to
                        mislead
                        any
                        person as to the identity or origin of any communication.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Spamming and Illegal Activities</p>
                    <p class="paragraph">You agree to be solely responsible for the contents of your
                        transmissions
                        through
                        the
                        Services. You agree not to use the Services for illegal purposes or for the transmission
                        of
                        material
                        that is unlawful, defamatory, harassing, libelous, invasive of another's</p>
                    <p class="paragraph">privacy, abusive, threatening, harmful, vulgar, pornographic, obscene,
                        or
                        is
                        otherwise
                        objectionable, offends religious sentiments, promotes racism, contains viruses or
                        malicious
                        code, or
                        that which infringes or may infringe intellectual property or other rights of another.
                        You
                        agree
                        not
                        to
                        use the Services for the transmission of "junk mail", "spam", "chain letters",
                        “phishing” or
                        unsolicited
                        mass distribution of email. We reserve the right to terminate your access to the
                        Services if
                        there
                        are
                        reasonable grounds to believe that you have used the Services for any illegal or
                        unauthorized
                        activity.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Inactive User Accounts Policy</p>
                    <p class="paragraph">We reserve the right to terminate unpaid user accounts that are
                        inactive
                        for a
                        continuous
                        period of 120 days. In the event of such termination, all data associated with such user
                        account
                        will be
                        deleted. We will provide you prior notice of such termination and option to
                        <NOBR>back-up</NOBR>
                        your data. The data deletion policy may be implemented with respect to any or all of the
                        Services.
                        Each
                        Service will be considered an independent and separate service for the purpose of
                        calculating
                        the
                        period
                        of inactivity. In other words, activity in one of the Services is not sufficient to keep
                        your
                        user
                        account in another Service active. In case of accounts with more than one user, if at
                        least
                        one
                        of
                        the
                        users is active, the account will not be considered inactive.
                    </p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Data Ownership</p>
                    <p class="paragraph">We respect your right to ownership of content created or stored by you.
                        You
                        own
                        the
                        content created or stored by you. Unless specifically permitted by you, your use of the
                        Services
                        does
                        not grant HouseOfBanquet the license to use, reproduce, adapt, modify, publish or distribute
                        the
                        content
                        created by you or stored in your user account for HouseOfBanquet’s commercial, marketing or
                        any
                        similar
                        purpose. But you grant HouseOfBanquet permission to access, copy, distribute, store,
                        transmit,
                        reformat,
                        publicly display and publicly perform the content of your user account solely as
                        required
                        for
                        the
                        purpose of providing the Services to you.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Sample fles and Applications</p>
                    <p class="paragraph">HouseOfBanquet may provide sample files and applications for the purpose of
                        demonstrating
                        the
                        possibility of using the Services effectively for specific purposes. The information
                        contained
                        in
                        any
                        such sample files and applications consists of random data. HouseOfBanquet makes no warranty,
                        either
                        express
                        or implied, as to the accuracy, usefulness, completeness or reliability of the
                        information
                        or
                        the
                        sample
                        files and applications.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Trademark</p>
                    <p class="paragraph">HouseOfBanquet, HouseOfBanquet logo, the names of individual Services and their
                        logos
                        are
                        trademarks of HouseOfBanquet BUSINESS SOLUTIONS PRIVATE LIMITED. You agree not to display or
                        use,
                        in
                        any
                        manner, the HouseOfBanquet trademarks, without HouseOfBanquet’s prior permission.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Disclaimer of Warranties</p>
                    <p class="paragraph">YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE SERVICES IS AT
                        YOUR
                        SOLE
                        RISK.
                        THE
                        SERVICES ARE PROVIDED ON AN
                        <NOBR>AS-IS-AND-AS-AVAILABLE</NOBR>
                        BASIS. HouseOfBanquet EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                        IMPLIED,
                        INCLUDING,
                        BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                        PARTICULAR
                        PURPOSE.
                        HouseOfBanquet MAKES NO WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
                        ERROR
                        FREE.
                        USE
                        OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE SERVICES SHALL BE AT YOUR
                        OWN
                        DISCRETION
                        AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM,
                        MOBILE
                        TELEPHONE,
                        WIRELESS DEVICE OR DATA THAT RESULTS FROM THE USE OF THE SERVICES OR THE DOWNLOAD OF ANY
                        SUCH
                        MATERIAL.
                        NO ADVICE OR INFORMATION, WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM HouseOfBanquet, ITS
                        EMPLOYEES
                        OR
                        REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
                    </p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Limitation of Liability</p>
                    <p class="paragraph">YOU AGREE THAT HouseOfBanquet SHALL, IN NO EVENT, BE LIABLE FOR ANY
                        CONSEQUENTIAL,
                        INCIDENTAL,
                        INDIRECT, SPECIAL, PUNITIVE, OR OTHER LOSS OR DAMAGE WHATSOEVER OR FOR LOSS OF BUSINESS
                        PROFITS,
                        BUSINESS INTERRUPTION, COMPUTER FAILURE, LOSS OF BUSINESS INFORMATION, OR OTHER LOSS
                        ARISING
                        OUT
                        OF
                        OR
                        CAUSED BY YOUR USE OF OR INABILITY TO USE THE SERVICE, EVEN IF HouseOfBanquet HAS BEEN
                        ADVISED OF
                        THE
                        POSSIBILITY OF SUCH DAMAGE. IN NO EVENT SHALL HouseOfBanquet’S ENTIRE LIABILITY TO YOU IN
                        RESPECT
                        OF
                        ANY
                        SERVICE,</p>
                </b-col>
                <b-col sm="12">
                    <p class="paragraph">WHETHER DIRECT OR INDIRECT, EXCEED THE FEES PAID BY YOU TOWARDS SUCH
                        SERVICE.</p>
                    <p class="sub-heading">Indemnifcation</p>
                    <p class="paragraph">You agree to indemnify and hold harmless HouseOfBanquet, its officers,
                        directors,
                        employees, suppliers, and affiliates, from and against any losses, damages, fines and
                        expenses
                        (including attorney's fees and costs) arising out of or relating to any claims that you
                        have
                        used
                        the Services in violation of another party's rights, in violation of any law, in
                        violations
                        of
                        any
                        provisions of the Terms, or any other claim related to your use of the Services, except
                        where
                        such
                        use is authorized by HouseOfBanquet.</p>
                </b-col>
                <b-col sm="12">
                    <p class="sub-heading">Suspension and Termination</p>
                    <span class="paragraph">We may suspend your user account or temporarily disable access to whole
                                or
                                part
                                of
                                any Service in the event of any suspected illegal activity, extended periods of
                                inactivity
                                or
                                requests by law enforcement or other government agencies. Objections to suspension or
                                disabling
                                of
                                user accounts should be made to <a href="mailto:info@houseofbanquets.com"><span
                            class="ft5">info@houseofbanquets.com</span></a>
                                within thirty days of being notified about the suspension. We may terminate a suspended
                                or
                                disabled
                                user account after thirty days. We will also terminate your user account on your
                                request.
                                <p class="paragraph">In addition, we reserve the right to terminate your user account and
                                deny
                                the
                                Services upon reasonable belief that you have violated the Terms and to terminate your
                                access to
                                any
                                Beta Service in case of unexpected technical issues or discontinuation of the Beta
                                Service.
                                You
                                have
                                the right to terminate your user account if HouseOfBanquet breaches its obligations under
                                these
                                Terms
                                and
                                in such event, you will be entitled to prorated refund of any prepaid fees. Termination
                                of
                                user
                                account will include denial of access to all Services, deletion of information in your
                                user
                                account
                                such as your email address and password and deletion of all data in your user
                                account.</p>
                            </span>
                </b-col>
                <b-col sm="12">
                    <div>
                        <p class="sub-heading">END OF TERMS OF SERVICE</p>
                    </div>
                    <div>
                                <span class="paragraph">If you have any questions or concerns regarding this Agreement,
                                    please
                                    contact
                                    us
                                    at
                                    <span class="ft7">info@houseofbanquets.com</span>
                                </span>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>


<script>
    export default {
        data() {
            return {}
        }
    }
</script>

<style scoped>
    #terms-and-condition {
        margin: 0px auto;
    }

    .paragraph {
        font: 18px 'Arial';
        line-height: 22px;
    }

    #date-right p {
        font-size: 14px;
        font-weight: 400;
    }


    .ft5 {
        font: 18px 'Arial';
        text-decoration: underline;
        color: #0000ff;
        line-height: 22px;
    }

    .ft6 {
        font: 18px 'Arial';
        text-decoration: underline;
        color: #03a9f5;
        line-height: 22px;
    }

    .ft7 {
        font: 18px 'Arial';
        text-decoration: underline;
        color: #03a9f5;
        line-height: 23px;
    }

    h1 {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
    }

    .sub-heading {
        text-align: left;
        font-size: 22px;
        font-weight: bold;
        margin-top: 20px;
    }

    .paragraph {
        margin-top: 5px;
        margin-bottom: 5px;
        text-align: left;
        font-size: 16px;
        line-height: 25px;
    }
</style>
